import React from "react";
import { graphql } from "gatsby";

import Layout from "../Layout/Layout";
import Portfolio from "../modules/Portfolio/Portfolio";
import SEO from "../Layout/Seo";

const PagePortfolioTemplate = props => {
  const currentPage = props.data.wordpressPage;
  const portfolio = props.data.allWordpressWpPortfolio;

  return (
    <Layout>
      <SEO
        title={currentPage.yoast.title ? currentPage.yoast.title : currentPage.title}
        keywords={currentPage.yoast.metakeywords}
        description={currentPage.yoast.metadesc}
      />
      <div className="portfolio portfolio-wrapper">
        <div className="section__header portfolio-list__header">
          <h2 className="section__title--large">{currentPage.title}</h2>
        </div>

        <div className="portfolio-grid">
          {portfolio.nodes.map(option => {
            return option.acf.mock_up_main != null ? (
              <Portfolio portfolioData={option} key={option.id} />
            ) : null;
          })}
        </div>
      </div>
    </Layout>
  );
};

export default PagePortfolioTemplate;

export const pageQuery = graphql`
  query currentPortfolioPageMainQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      id
      link
      yoast {
        title
        metakeywords
        metadesc
      }
      date(formatString: "MMMM DD, YYYY")
    }
    allWordpressWpPortfolio {
      nodes {
        id
        title
        link
        path
        acf {
          client_reference
          client_position
          client_name
          country_flag
          project_logo {
            title
            link
          }
          client_photo {
            id
            link
          }
          mock_up_main {
            link
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          page_logo {
            id
            source_url
            alt_text
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          services {
            icon
            name
          }
          short_text
          shop_link
          country_flag
          what_wewe_done
        }
        wordpress_id
      }
    }
  }
`;
