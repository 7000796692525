import React from "react";
import Item from "./components/Item";
import renderHTML from "../../helpers/renderHTML";

const Portfolio = props => {
  const portfolioid = props.portfolioData.wordpress_id;
  const data = props.portfolioData;
  const options = props.portfolioData.acf.services;
  const mainUrl = props.homepageUrl;

  const flagsImages = require.context("../../images/flags", true);
  const flagPath = name => flagsImages(name, true);

  return (
    <div className="portfolio__single-box">
      <div className="portfolio__half--second">
        <div
          className={`portfolio__wrapper portfolio-list__wrapper portfolio-list__wrapper ${
            portfolioid === 68 || portfolioid === 59
              ? "portfolio-list__wrapper--left"
              : "portfolio-list__wrapper--right"
          }`}
        >
          {data.acf.mock_up_main != null ? (
            <div
              className={`portfolio__image lazyload ${
                portfolioid === 68 || portfolioid === 59
                  ? "portfolio-list__image--left"
                  : "portfolio-list__image--right"
              }`}
              data-bg={data.acf.mock_up_main.localFile.childImageSharp.original.src}
            />
          ) : null}
        </div>
      </div>

      <div className="portfolio__item portfolio-list__item">
        <div className="container portfolio__full">
          <div
            className={`portfolio__half ${portfolioid % 2 !== 1 ? "half--first" : "half--second"}`}
          />
          <div
            className={`portfolio__half ${
              portfolioid === 68 || portfolioid === 59
                ? "half--second portfolio__half__offset--one"
                : "half--first"
            }`}
          >
            <div className="portfolio__half__wrapper">
              <div className="item__logo">
                {data.acf.page_logo != null ? (
                  <img
                    className="lazyload"
                    data-src={data.acf.page_logo.localFile.childImageSharp.original.src}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsOaZdDwAFAQHms1Zh0AAAAABJRU5ErkJggg=="
                    alt={data.acf.page_logo.alt_text}
                  />
                ) : null}
              </div>

              <div className="portfolio__item__description portfolio__item__description--list">
                <span
                  className="description__text"
                  dangerouslySetInnerHTML={renderHTML(data.acf.short_text)}
                />
              </div>
              <div className="portfolio__item__country">
                <img
                  className="portfolio__item__flag"
                  src={flagPath(data.acf.country_flag.replace("images/flags", "."))}
                  alt="flag"
                />
                <span className="portfolio__item__link">{data.acf.shop_link}</span>
              </div>

              <span className="portfolio-services__title">{data.acf.what_wewe_done}</span>
              <ul className="portfolio-services">
                {options.map(option => (
                  <Item option={option} mainPageUrl={mainUrl} key={option.name} />
                ))}
              </ul>
              <a className="btn__cta btn__cta--green btn__cta--invers" href={data.path}>
                read more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
